<template>
	<div class="page_setting">
		<top :bgWhite='true'></top>
		<div class="contentAll  padding-lr-30  top-80   ">
			<p class="fs11 text-333 bold">修改密码> 设置/修改支付密码> 修改绑定手机</p>
			<div class="margin-top-20 bg-white padding">
				<div class="flex align-center  padding-bottom-15  solid-bottom">
					<div class="" v-for="(item,index) in navList" :key='index'>
						<span class="margin-right-35 padding-bottom-15 padding-lr-10 text-666 point fs14" @click="tabChange(item,index)"
							:class="currentIndex==index?'active':''">{{item.name}}</span>
					</div>
				</div>
				<!-- 修改密码 -->
				<change-password v-if="currentIndex==0" @changePsw="changePsw"></change-password>
				<!-- 修改支付密码 -->
				<change-payPassword v-if="currentIndex==1" @changePsw="changePsw"></change-payPassword>
				<!-- 修改绑定手机号 -->
				<change-phone v-if="currentIndex==2 && step==1" @showNext="showNext" ></change-phone>
				<bind-phone v-if="currentIndex==2 && step==2"></bind-phone>
			</div>
		</div>
		<bottom :bgWhite='true'></bottom>
	</div>
</template>

<script>
	
	import top from '@/components/top'
	import bottom from '@/components/bottom'
	import changePassword from '@/components/set/change_password'
	import changePayPassword from '@/components/set/change_payPassword.vue'
	import changePhone from '@/components/set/change_phone'
	import bindPhone from '@/components/set/step_two.vue'
	export default {
		components: {
			bottom,
			top,
			changePassword,
			changePhone,
			bindPhone,
			changePayPassword
		},
		data(){
			return{
				step:1,
				currentIndex:0,
				navList: [{
					name: '修改密码',
					id: 1,
				}, {
					name: '设置/修改支付密码',
					id: 2,
				},{
					name: '修改绑定手机',
					id: 3,
				}]
			}
		},
		created() {
			this.currentIndex = this.$route.query.type || 0
		},
		methods:{
			// 修改密码
			changePsw(){
				setTimeout(()=>{
					this.$router.go(-1)
				},500)
			},
			tabChange(item,index){
				this.currentIndex = index
			},
			// 绑定手机号下一步
			showNext(){
				this.step = 2
			}
		}
	}
</script>

<style  lang="scss">
@import './setting.scss';
html, body{
	background: #F6F7F9 !important;
}
</style>