<template>
	<div>
		<!-- 账号登录 -->
		<div class="padding-top-20 flex flex-direction justify-center align-center">
			<div class="fs16 bold  w100 padding">验证旧手机</div>
			<div :class="['item', 'account', errorTips == 3 ? 'msg-error' : '']"
				class="padding-lr-15 margin-bottom-20 flex align-center">
				<img src="../../assets/image/phone.png" class="width25 height20 margin-right-10">
				<input type="text" onkeyup="value=value.replace(/[^\d]/g,'')" maxlength="11" class="input" disabled v-model="mobileX"
					placeholder="请输入手机号" />
				<div class="cancel" @click="clearInput($event)" data-type="mobile"></div>
			</div>
			<div :class="['item', 'code', errorTips == 2 ? 'msg-error' : '']"
				class="padding-lr-15 flex align-center justify-between margin-bottom-20">
				<div class="flex align-center">
					<img src="../../assets/image/code.png" class="width16 height20 margin-right-10">
					<input type="text" class="input" v-model="validCode" onkeyup="value=value.replace(/[^\d]/g,'')"
						placeholder="请输入验证码" maxlength="4" />
				</div>
				<span class="send-code point" v-show="show" @click="getCode">获取验证码</span>
				<span v-show="!show" class="send-code" style="cursor: not-allowed;">{{ count }} s</span>
			</div>
			<!-- 完成按钮 -->
			<div class="margin-top-50">
				<el-button type="danger" round  class="confirm_btn" @click="nextStep">下一步</el-button>
			</div>
		</div>
		<!-- /账号登录 -->
	</div>
</template>

<script>
	import {
		validate
	} from '../../assets/js/validate';
	export default {
		data(){
			return{
				mobile: "", // {String} 手机号
				mobileX: "", // {String} 当前手机号添加*号之后的显示
				validCode: '',
				userName: '',
				password: '',
				errorTips: 0, // 输入错误提示:  1手机号输入错误  2验证码输入错误  3账号输入错误  4密码输入错误 5图形验证码错误 6未同意条款
				// 验证码相关
				show: true, //是否展示倒计时
				count: '', //倒计时
				loginType: 0, //错误提示
				new_mobile:'', //新手机号
				newPassword: '', //确认密码
				timer: null,
				hadGotCode: false,
				isLogging: false, //是否正在登录
			}
		},
		created() {
			this.getMobile()
		},
		methods:{
			/**
			 * @description 获取手机号
			 * @property {String} mobile - 加密后的手机号
			 */
			getMobile() {
			  this.ajax('post', '/v1/5f69ee219127f', {}, res => {
			  	console.log(res);
			  	if (res.code == 1) {
			  		var mobile =
			  		  res.data.substr(0, 3) + "****" + res.data.substr(7);
			  		this.mobile = res.data;
			  		this.mobileX = mobile;
			  	} else {
			  		this.$message.error(res.msg);
			  	}
			  }, err => {
			  	console.log(err);
			  })
			},
			/**
			 * 获取登录验证码
			 */
			getCode() {
				if (!this.mobile) {
					this.$message.error('请输入手机号');
					return false;
				}
				if (!validate(this.mobile, 'phone')) {
					this.$message.error('请正确输入手机号');
					return false;
				}
				if (this.hadGotCode) {
					return
				}
				let sendSmsParam = {
					mobile: this.mobile,
					is_test: this.$store.state.is_dev,
					type: '4'
				}
				this.ajax('post', '/v1/5b5bdc44796e8', sendSmsParam, res => {
					console.log(res);
					this.hadGotCode = true
					if (res.code == 1) {
						const timeCount = 60;
						if (!this.timer) {
							this.count = timeCount;
							this.show = false;
							this.validCode = res.data.code
							this.timer = setInterval(() => {
								if (this.count > 0 && this.count <= timeCount) {
									this.count--;
								} else {
									this.show = true;
									clearInterval(this.timer);
									this.timer = null;
									this.hadGotCode = false
								}
							}, 1000)
						}
					} else {
						this.hadGotCode = false;
						this.$message.error(res.msg);
					}
			
				}, err => {
					console.log(err);
					this.hadGotCode = false
				})
			},
			// 确认更换
			nextStep(){
				if(!this.hadGotCode){
					this.$message.error('请先获取验证码');
					return false
				};
				if(!this.validCode){
					this.$message.error('请先输入验证码');
					return false
				};
				// 请求接口时携带的数据
				let data = {
				  mobile: this.mobile,
				  code: this.validCode,
				  type: 4
				};
				this.ajax('post', '/v1/5f6db4db8abcf', data, res => {
					console.log(res);
					this.hadGotCode = true
					if (res.code == 1) {
						this.$emit('showNext')
					} else {
						this.hadGotCode = false;
						this.$message.error(res.msg);
					}
							
				}, err => {
					console.log(err);
					this.hadGotCode = false
				})
			}
		}
	}
</script>

<style scoped lang="scss">
.item {
	width: 450px;
	height: 48px;
	line-height: 48px;
	background-color: #F6F7F9 !important;
	border: 0;
	border-radius: 24px;
	.input {
		width: 100%;
		font-size: 14px;
		background: none;
	}
	.send-code{
		font-size: 9px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		line-height: 13px;
		color: #3E62B1;
		opacity: 1;
	}
}
.confirm_btn{
	width: 148px;
	height: 40px;
	background: #E54F42;
	opacity: 1;
	border-radius: 20px;
}
</style>